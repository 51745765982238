<template>
  <v-container fluid>
    <v-row style="position: sticky; top: 64px; z-index: 2">
      <v-col cols="12">
        <PageHeader>
          <span slot="courses" class="font-weight-light">MOUNT </span> PCLS
        </PageHeader>
        <v-card>
          <v-card-text>
            <v-form ref="courseHeader">
              <v-row justify="center">
                <v-col>
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        @change="programDetails"
                        @keyup="debouncesearchProgram"
                        :loading="loadProgram"
                        v-model="program"
                        :items="itemsProgram"
                        :search-input.sync="searchProgram"
                        clearable
                        hide-details="auto"
                        hide-selected
                        item-text="program_name_certificate"
                        class="rounded-0"
                        item-value="id"
                        label="Search Program"
                        outlined
                        :rules="[(v) => !!v || 'Program is required...']"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title v-if="loadProgram">
                              <v-progress-circular
                                color="primary"
                                indeterminate
                              ></v-progress-circular>
                              <span class="ml-5">
                                Searching
                                <strong>Program...</strong>
                              </span>
                            </v-list-item-title>
                            <v-list-item-title v-else>
                              No
                              <strong>Record(s)...</strong>
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col>
                      <v-select
                        :menu-props="{ down: true, offsetY: true }"
                        :loading="islevelLoaded"
                        :disabled="loadedProgram"
                        :items="itemsGrade"
                        class="rounded-0"
                        item-text="name"
                        item-value="id"
                        outlined
                        label="Grading System"
                        hide-details="auto"
                        v-model="grade"
                        hide-selected
                        :rules="[(v) => !!v || 'Grading System is required...']"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title>
                              Loading
                              <strong>Grading Systems...</strong>
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        :menu-props="{ down: true, offsetY: true }"
                        :items="basket_campus"
                        item-value="id"
                        item-text="campus_name"
                        hide-details="auto"
                        outlined
                        multiple
                        class="rounded-0"
                        label="Campus"
                        @click="fetchCampus"
                        v-model="campus"
                        :rules="[(v) => !!v || 'Campus is required...']"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title>
                              Loading
                              <strong>Campus...</strong>
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select
                        :menu-props="{ down: true, offsetY: true }"
                        :loading="iscalendarLoaded"
                        v-model="calendar"
                        class="rounded-0"
                        :items="basket_calendar"
                        item-text="academic_calendar"
                        item-value="id"
                        outlined
                        label="Calendar"
                        @click="fetchCalendar"
                        hide-details="auto"
                        :rules="[(v) => !!v || 'Calendar is required...']"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title>
                              Loading
                              <strong>Calendar(s)...</strong>
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        :menu-props="{ down: true, offsetY: true }"
                        :loading="issemesterLoaded"
                        @click="fetchSemester"
                        v-model="semester"
                        :items="basket_semester"
                        class="rounded-0"
                        item-value="id"
                        item-text="semester"
                        outlined
                        label="Semester"
                        hide-details="auto"
                        hide-selected
                        :rules="[(v) => !!v || 'Semester is required...']"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title>
                              Loading
                              <strong>Semesters...</strong>
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        :menu-props="{ down: true, offsetY: true }"
                        :loading="islevelLoaded"
                        :disabled="loadedProgram"
                        :items="basket_level"
                        item-text="level"
                        item-value="id"
                        @change="selectedLevel"
                        outlined
                        class="rounded-0"
                        label="Level"
                        hide-details="auto"
                        v-model="level"
                        hide-selected
                        :rules="[(v) => !!v || 'Level is required...']"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title>
                              Loading
                              <strong>Levels...</strong>
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col
                      v-if="ifIntake"
                      transition="slide-x=reverse-transition"
                    >
                      <v-select
                        :menu-props="{ down: true, offsetY: true }"
                        :loading="isintakeLoaded"
                        :items="basket_intake"
                        item-text="intake"
                        class="rounded-0"
                        item-value="id"
                        outlined
                        label="Intake"
                        v-model="intake"
                        hide-details="auto"
                        hide-selected
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-overlay :absolute="absolute" :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-col>
    </v-row>

    <p v-show="showCourses" class="text-uppercase grey--text mt-5">COURSES</p>

    <v-row>
      <v-col>
        <v-form ref="mountCourseForm" @submit.prevent>
          <v-slide-y-transition color="primary" group>
            <v-card
              outlined
              v-show="showCourses"
              v-for="(course, i) in basket"
              :key="i"
              tile
              class="mb-1"
            >
              <v-row align="center" justify="center">
                <v-col class="pl-8" cols="12" lg="1">
                  <v-avatar size="30" color="primary">
                    <span class="white--text headline">{{ i + 1 }}</span>
                  </v-avatar>
                </v-col>
                <v-col cols="12" lg="2">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-subtitle> Course Code </v-list-item-subtitle>
                      <v-list-item-title
                        v-text="course.course_code"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-subtitle>Course Name</v-list-item-subtitle>
                      <v-list-item-title v-text="course.course_name">
                        Secondary text
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col class="text-center">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-subtitle>Credit Hours</v-list-item-subtitle>
                      <v-list-item-title v-text="course.credit_hours">
                        Secondary text
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-card>
          </v-slide-y-transition>
        </v-form>

        <v-footer inset v-if="showCourses">
          <v-spacer></v-spacer>
          <v-btn
            x-large
            color="primary"
            :disabled="
              !getters_abilities.includes('save_mounted_courses_access')
            "
            @click="saveMountedCoursesBtn"
          >
            Save Courses
          </v-btn>
        </v-footer>
      </v-col>
    </v-row>

    <Response v-if="response">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>
<script>
  import PageHeader from "@/components/slots/PageHeader";
  import { getCurrentInstance, provide, reactive, ref, toRefs, watch } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import debounce from "@/debounce/debounce";
  import Response from "@/components/ActionResponse/Response";
  import Api from "@/apis/Api";
  export default {
    components: { PageHeader, Response },
    setup() {
      const vm = getCurrentInstance()
      const {
        getHodDeptProg,
        getSemester,
        getCurCalendar,
        getLevel,
        getIntake,
        saveMountedCourses,
        ifheaderExists,
        getCampus,
        signOut,
      } = useActions([
        "getHodDeptProg",
        "getSemester",
        "getCurCalendar",
        "getLevel",
        "getIntake",
        "getSession",
        "getCampus",
        "saveMountedCourses",
        "ifheaderExists",
        "getCampus",
        "signOut",
      ]);

      const {
        getters_hoddeptprog,
        getters_semester,
        getters_curcalendar,
        getters_level,
        getters_intake,
        getters_campus,
        getters_abilities,
      } = useGetters([
        "getters_hoddeptprog",
        "getters_semester",
        "getters_curcalendar",
        "getters_level",
        "getters_intake",
        "getters_campus",
        "getters_abilities",
      ]);

      const courseHeader = reactive({
        get_program_info: [],
        iscalendarLoaded: false,
        issemesterLoaded: false,
        islevelLoaded: false,
        isintakeLoaded: false,
        iscampusLoaded: false,
        loadedProgram: true,
        program: "",
        calendar: "",
        semester: "",
        level: "",
        intake: "",
        campus: [],
        basket_calendar: [],
        basket_semester: [],
        basket_level: [],
        basket_intake: [],
        basket_campus: [],
        ifIntake: false,
        itemsProgram: [],
        itemsGrade: [],
        loadProgram: false,
        searchProgram: "",
        resitSwitch: false,
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        response: false,
        grade: null,
        color: "",
      });

      const {
        color,
        grade,
        loadedProgram,
        msgHeader,
        msgBody,
        msgIcon,
        response,
        searchProgram,
        itemsProgram,
        loadProgram,
        basket_calendar,
        basket_semester,
        basket_level,
        basket_campus,
        basket_intake,
        ifIntake,
        program,
        calendar,
        semester,
        campus,
        level,
        intake,
        iscalendarLoaded,
        issemesterLoaded,
        islevelLoaded,
        isintakeLoaded,
        iscampusLoaded,
        resitSwitch,
        get_program_info,
        itemsGrade,
      } = toRefs(courseHeader);

      const mountCourseForm = reactive({
        mountLecturer: [],
        basket: [],
        loadCourse: false,
        showCourses: false,
        errorMsgs: [],
        courseBasket: [],
        grade: null,
      });

      const absolute = ref(true);
      const overlay = ref(false);
      const courseheaderFeedback = ref(false);

      const { basket, showCourses, errorMsgs, courseBasket } =
        toRefs(mountCourseForm);

      watch(
        () => [
          calendar.value,
          semester.value,
          level.value,
          intake.value,
          program.value,
          campus.value,
          grade.value,
        ],
        () => {
          if (
            calendar.value &&
            semester.value &&
            level.value &&
            intake.value &&
            program.value &&
            campus.value &&
            grade.value
          ) {
            let header = {
              calendar_id: calendar.value,
              semester_id: semester.value,
              level_id: level.value,
              intake_id: intake.value,
              program_id: program.value,
              campus_id: campus.value,
              grade_id: grade.value,
            };
            overlay.value = true;
            response.value = false;
            ifheaderExists(header)
              .then(() => {
                Api()
                  .post("/getprogramcourselevelsemester", {
                    semester_id: semester.value,
                    level_id: level.value,
                    program_id: program.value,
                  })
                  .then((res) => {
                    showCourses.value = true;
                    overlay.value = false;
                    basket.value = res.data.data.map((item) => item.course);
                  })
                  .catch((e) => {
                    response.value = true;
                    msgIcon.value = "mdi-close-circle";
                    msgHeader.value = "Error";
                    color.value = "error";
                    overlay.value = false;
                    showCourses.value = false;
                    courseheaderFeedback.value = true;
                    switch (e.response.status) {
                      case 403:
                        msgBody.value = e.response.data.message;

                        break;
                      case 404:
                        msgBody.value = e.response.data.message;
                        break;

                      case 422:
                        msgBody.value = e.response.data.message;
                        break;

                      default:
                        msgBody.value =
                          "Try Again Later Or Call The System Administrator";
                        break;
                    }
                  });
              })
              .catch((e) => {
                response.value = true;
                msgIcon.value = "mdi-close-circle";
                msgHeader.value = "Error";
                color.value = "error";
                overlay.value = false;
                showCourses.value = false;
                courseheaderFeedback.value = true;
                switch (e.response.status) {
                  case 403:
                    msgBody.value = e.response.data.message;

                    break;
                  case 404:
                    msgBody.value = e.response.data.message;
                    break;

                  default:
                    msgBody.value =
                      "Try Again Later Or Call The System Administrator";
                    break;
                }
              });
          }
        }
      );

      provide("color", color);

      const debouncesearchProgram = debounce(() => {
        if (searchProgram.value != null || searchProgram.value != "undefined") {
          if (itemsProgram.value.length > 0) return;
          loadProgram.value = true;
          getHodDeptProg()
            .then(() => {
              itemsProgram.value = getters_hoddeptprog.value;
            })
            .finally(() => (loadProgram.value = false));
        }
      });

      const fetchCalendar = () => {
        iscalendarLoaded.value = true;
        if (getters_curcalendar.value.length > 0) {
          iscalendarLoaded.value = false;
          basket_calendar.value = getters_curcalendar.value;
          return;
        }
        getCurCalendar().then(() => {
          basket_calendar.value = getters_curcalendar.value;
          iscalendarLoaded.value = false;
        });
      };

      const programDetails = (i) => {
        if (!i) return;
        islevelLoaded.value = true;
        loadedProgram.value = true;
        ifIntake.value = false;
        intake.value = "";
        level.value = "";
        get_program_info.value = getters_hoddeptprog.value.find(
          (item) => item.id === i
        );
        itemsGrade.value = get_program_info.value.grades;
        getLevel().then(() => {
          if (get_program_info.value.program_state) {
            basket_level.value = getters_level.value.filter(
              (item) => item.level >= 300
            );
          } else {
            basket_level.value = getters_level.value;
          }
          islevelLoaded.value = false;
        });

        loadedProgram.value = false;
      };

      const fetchIntake = () => {
        isintakeLoaded.value = true;
        if (getters_intake.value.length > 0) {
          basket_intake.value = getters_intake.value;
          isintakeLoaded.value = false;
          return;
        }

        getIntake();
        basket_intake.value = getters_intake.value;
        isintakeLoaded.value = false;
      };

      const selectedLevel = (level) => {
        intake.value = null;
        var get_level = getters_level.value.find((item) => item.id === level);
        if (get_program_info.value.program_state && get_level.level === 300) {
          basket_intake.value = getters_intake.value.filter(
            (item) => item.intake.toLowerCase() != "all" && item.current
          );
          ifIntake.value = true;
        } else if (
          !get_program_info.value.program_state &&
          get_level.level === 300
        ) {
          basket_intake.value = getters_intake.value.filter(
            (item) => item.intake.toLowerCase() == "all"
          );
          ifIntake.value = false;

          intake.value = basket_intake.value[0].id;
        } else if (get_level.level === 200 || get_level.level === 400) {
          ifIntake.value = false;
          basket_intake.value = getters_intake.value.filter(
            (item) => item.intake.toLowerCase() === "all"
          );
          intake.value = basket_intake.value[0].id;
        } else {
          ifIntake.value = true;
          basket_intake.value = getters_intake.value.filter(
            (item) => item.intake.toLowerCase() !== "all" && item.current
          );
        }
      };

      const fetchSemester = () => {
        issemesterLoaded.value = true;
        if (getters_semester.value.length > 0) {
          basket_semester.value = getters_semester.value;
          issemesterLoaded.value = false;
          return;
        }

        getSemester().then(() => {
          basket_semester.value = getters_semester.value;
          issemesterLoaded.value = false;
        });
      };

      const fetchCampus = () => {
        campus.value = [];
        iscampusLoaded.value = true;
        if (getters_campus.value.length > 0) {
          basket_campus.value = getters_campus.value;
          iscampusLoaded.value = false;
          basket_campus.value.forEach((item) => campus.value.push(item.id));
          return;
        }

        getCampus().then(() => {
          basket_campus.value = getters_campus.value;
          basket_campus.value.forEach((item) => campus.value.push(item.id));

          iscampusLoaded.value = false;
        });
      };

      const ifcourseExist = () => {
        let thisbasket = [];
        basket.value.forEach((row) => {
          if (!thisbasket.includes(row.course)) {
            thisbasket.push(row.course);
            courseBasket.value = thisbasket;
            row.error = "";
            errorMsgs.value = [];
          } else {
            row.error = "duplicate found";
            errorMsgs.value.push(row.error);
          }
        });
      };

      fetchIntake();

      const saveMountedCoursesBtn = () => {
        if (errorMsgs.value.length === 0) {
          overlay.value = true;
          const mybasket = {
            calendar_id: calendar.value,
            program_id: program.value,
            courses_id: basket.value.map((item) => item.id),
            level_id: level.value,
            semester_id: semester.value,
            intake_id: intake.value,
            campus_id: campus.value,
            is_resit: resitSwitch.value,
            grade_id: grade.value,
          };
          saveMountedCourses(mybasket)
            .then(() => {
              overlay.value = false;
              vm.proxy.$refs.courseHeader.reset();
              vm.proxy.$refs.mountCourseForm.reset();
              showCourses.value = false;
              resitSwitch.value = false;
            })
            .catch((e) => {
              overlay.value = false;
              response.value = true;
              msgHeader.value = "Error";
              msgIcon.value = "mdi-close-circle";
              vm.proxy.$refs.facultyForm.reset();
              switch (e.response.status) {
                case 403:
                  msgBody.value = "This action is unauthorized";
                  break;

                case 423:
                  msgBody.value = e.response.data.message;
                  signOut().then(() => {
                    vm.proxy.$router.replace({
                      name: "Login",
                    });
                  });
                  break;

                default:
                  msgBody.value =
                    "Try Again Later Or Call The System Administrator";
                  break;
              }
            });
        }
      };

      return {
        debouncesearchProgram,
        ...toRefs(mountCourseForm),
        ...toRefs(courseHeader),
        fetchCalendar,
        programDetails,
        fetchIntake,
        fetchSemester,
        fetchCampus,
        selectedLevel,
        saveMountedCoursesBtn,
        ifcourseExist,
        absolute,
        overlay,
        courseheaderFeedback,
        getters_abilities,
      };
    },
  };
</script>
